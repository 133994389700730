import { Component } from '@angular/core';
import { DeviceRouterService } from '../services/device-router.service';
import { MobileAppHeaderComponent } from './components/mobile-app-header/mobile-app-header.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MobileAppHeaderComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private deviceRouterService: DeviceRouterService) {
    this.deviceRouterService.navigateToDeviceApp();
  }
}
