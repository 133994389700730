import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mobile-app-header.component.html',
  styleUrls: ['./mobile-app-header.component.scss']
})
export class MobileAppHeaderComponent {

}
