import { Injectable } from '@angular/core';
import { environment } from '../environments/environment.development';
import _ from 'lodash';

const LOCAL_HOST = 'localhost';

@Injectable({
  providedIn: 'root'
})
export class DeviceRouterService {

  public isMobile(): boolean {
    return navigator.userAgent.match(/Android|iPhone|iPad|iPod/i) !== null;
  }

  public navigateToDesktopApp(): void {
    const domain = _.first(window.location.host.split('.'));
    window.location.assign(`${window.location.protocol}//${domain}${environment.desktopAppOrigin}${window.location.pathname}`);
  }

  public navigateToMobileApp(): void {
    const domain = _.first(window.location.host.split('.'));
    window.location.assign(`${window.location.protocol}//${domain}${environment.mobileAppOrigin}${window.location.pathname}`);
  }

  public navigateToDeviceApp(): void {
    if (window.location.origin.includes(LOCAL_HOST)) {
      console.debug(`Localhost detected on device ${(navigator as unknown as {userAgentData: {platform: string}}).userAgentData.platform}`);
    } else if(this.isMobile() && !window.location.origin.endsWith(environment.mobileAppOrigin)) {
      this.navigateToMobileApp();
    } else if (!this.isMobile() && window.location.origin.endsWith(environment.mobileAppOrigin)) {
      this.navigateToDesktopApp();
    }
  }
}
