import { Routes } from '@angular/router';
import { UnauthGuard } from './guards/unauth.guard';
import { FeatureMobileRtEventGuard } from './guards/feature-mobile-rt-event.guard';

export const routes: Routes = [
  {
    path: 'rt-event/:eventId',
    canActivate: [FeatureMobileRtEventGuard, UnauthGuard],
    loadComponent: () =>
      import('./pages/rt-event-page/rt-event-page.component').then(
        (m) => m.RtEventPageComponent
      ),
  },
  {
    path: '**',
    canActivate: [UnauthGuard],
    loadComponent: () =>
      import('./pages/page-not-found/page-not-found.component').then(
        (m) => m.PageNotFoundComponent
      ),
  },
];
