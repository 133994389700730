import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  concatMap,
  filter,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AuthenticationActions, AuthenticationSelectors } from './types';
import { AuthService } from '../services/auth.service';
import { AppState } from './app.state';
import { FeatureFlagService } from '../services/feature-toggle.service';
import { Model } from 'dashboard-frontend-library/models';
import { MixpanelService } from '../services/mixpanel.service';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private mixpanelService: MixpanelService,
    private store: Store<AppState>,
    private router: Router,
    private ngZone: NgZone
  ) {}

  onRegularUserAuth$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter((user) => user != null),
      concatMap((authUser) => {
        const authReq: Model.AuthenticatedRequest = {
          uid: authUser!.id,
        };
        return from(
          this.authService.setClaimsForRegularUsers(authReq, authUser!)
        );
      }),
      map((token) =>
        AuthenticationActions.updateTokenOnRegularUserSetClaims({ token })
      )
    );
  });

  onResetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthenticationActions.requestResetPassword),
      concatMap((action) =>
        from(this.authService.resetPassword(action.resetPasswordReq)).pipe(
          switchMap((result) => {
            if (result === true) {
              this.ngZone.run(() => {
                this.router.navigateByUrl('auth/password-recovery');
              });
            }
            return of(result);
          }),
          concatMap((result) => {
            return of(
              AuthenticationActions.passwordResetEmailChecked({
                passwordReset: {
                  email: action.resetPasswordReq.email,
                  isRegisteredUser: result,
                },
              })
            );
          })
        )
      )
    );
  });

  OnNewPassword$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.newPassword),
        switchMap((action) =>
          from(this.authService.confirmNewPassword(action.newPasswordReq)).pipe(
            switchMap((_) =>
              this.ngZone.run(() => this.router.navigateByUrl('page-not-found'))
            )
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  onAuthUserChange$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter((authUser) => authUser != null),
      switchMap((authUser) =>
        from(this.featureFlagService.init(authUser)).pipe(
          map(() => AuthenticationActions.saveAuthUser({ authUser }))
        )
      )
    );
  });

  onLogout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logout),
        switchMap((_) =>
          from(this.authService.logout()).pipe(
            switchMap((_) =>
              this.ngZone.run(() => this.router.navigateByUrl('auth/sign-in'))
            )
          )
        )
      );
    },
    {
      dispatch: false,
    }
  );

  logoutOnUserClick$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logoutOnUserClick),
        switchMap((_) =>
          from(this.authService.logoutFromAllOpenTab()).pipe(
            map((_) => AuthenticationActions.logout())
          )
        )
      );
    },
    { dispatch: true }
  );

  logoutFromAllTab$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logoutFromAllTab),
        take(1),
        tap((_) => {
          this.ngZone.run(() => this.router.navigateByUrl('/login'));
        })
      );
    },
    { dispatch: true }
  );

  refreshToken$ = createEffect(() => {
    return this.authService.currentAuthenticatedUser$.pipe(
      filter((user) => !!user),
      take(1), // the initial trigger is user logs in
      switchMap((_) => {
        const interval = 55 * 60 * 1000;
        return timer(interval, interval).pipe(
          //every 55 mins (token expires after 1 hour) get last user data, get claims from it (for regular user they will stay the same, for admin they might change)
          withLatestFrom(
            this.store.select(AuthenticationSelectors.selectAuthenticatedUser)
          ),
          filter(([_, user]) => !!user), // if user logs out,
          switchMap(([_, user]) => {
            const claims = { fleetId: user!.fleetId, shipId: user!.shipId };
            return from(this.authService.refreshToken(claims)).pipe(
              // refresh token
              map((token) =>
                AuthenticationActions.saveRefreshedToken({ token })
              )
            );
          })
        );
      })
    );
  });

  updateMixpanelUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.saveAuthUser),
        filter(action => action.authUser != null),
        concatMap(action => {
          this.mixpanelService.updateUser(action.authUser!);
          return of(null);
        })
      );
    },
    {
      dispatch: false,
    }
  );
}
