import { Model } from 'dashboard-frontend-library/models';
import {
  MOBILE_PLATFORM,
  MetricEvents,
  RT_EVENT_STATUS,
} from '../../app/models/metrics.model';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { RtEvent } from '../view-models/event.view.model';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private isMixpanelEnabled: boolean = true;

  constructor() {
    this.isMixpanelEnabled = environment.mixpanel.enabled;
    if (this.isMixpanelEnabled) {
      this.initMixpanel();
    }
  }

  initMixpanel(): void {
    mixpanel.init(environment.mixpanel.apikey, {
      api_host: environment.mixpanel.proxyUrl,
    });
  }

  addUserIdentity(user: Model.User): void {
    mixpanel.identify(user.id);
    mixpanel.people.set_once({
      $name: user.firstName,
      $email: user.email,
      companyName: user.companyName,
      role: user.role,
      lastName: user.lastName,
    });
  }

  updateTrackingBasedOnUser(user: Model.User): void {
    user.isTestUser ? mixpanel.opt_out_tracking() : mixpanel.opt_in_tracking();
  }

  sendMetricEvent(eventName: MetricEvents, data?: any): void {
    if (!this.isMixpanelEnabled) return;
    mixpanel.track(eventName, { ...data });
  }

  updateUser(user: Model.User): void {
    if (this.isMixpanelEnabled) {
      this.addUserIdentity(user);
      this.updateTrackingBasedOnUser(user);
    }
  }

  trackRTEvent(event: RtEvent, currentPageUrl: string) {
    this.sendMetricEvent('RtEventExploration', {
      ...event,
      rtEventStatus: event.timestampEnd
        ? RT_EVENT_STATUS.ENDED
        : RT_EVENT_STATUS.IN_PROGRESS,
      platform: MOBILE_PLATFORM,
      eventURL: currentPageUrl,
    });
  }
}
