import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureMobileRtEventGuard {
  canActivate(
  ): Observable<boolean> {
    return from([true]);
  }
}
