import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { createCustomElement } from '@angular/elements';
import { customElementsMap } from './app/custom-elements';

bootstrapApplication(AppComponent, appConfig)
  .then(appRef => {
    const injector = appRef.injector;
    Object.entries(customElementsMap).forEach(([selector, componentClass]) =>
      customElements.define(
        selector,
        createCustomElement(componentClass, { injector })
      )
    );
  })
  .catch(err => console.error(err));
