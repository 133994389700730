import { Type } from '@angular/core';
import { MapLabelComponent } from './widgets/mapbox/components/map-label/map-label.component';

export interface CustomElementsMap {
  [selector: string]: Type<any>;
}

export const customElementsMap: CustomElementsMap = {
  'app-map-label': MapLabelComponent,
};
